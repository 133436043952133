import React, { useState, useEffect } from 'react';
import { Input, Table, notification, Divider, Modal, Button, Select } from 'antd';
import { FaEdit, FaEye, FaToggleOn, FaToggleOff, FaPlus, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getEcoHistory, getHistory, getLicensaHistory } from '../../API/StaticGetters';
import dayJS from 'dayjs';
const { Option } = Select

const LogData = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [modalView, setModalView] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [selectedMenu, setSelectedMenu] = useState('users')
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])

    useEffect(() => {
        localStorage.setItem('title', 'Histórico de Actividades');
        localStorage.setItem('type', '2');
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        let dados = []
        dados = await getHistory()
        setData(dados)
        setLoading(false)
    }

    const columns = [
        {
            title: 'UID',
            dataIndex: 'user',
            key: 'user',
            width: 60,
            render: (text, record) => `${record.user.id}`,
            sorter: (a, b) => a.user.id - b.user.id,
        },
        {
            title: 'Nome do Usuário',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => `${record.user.email}`,
            sorter: (a, b) => a.user?.email.localeCompare(b.user?.email),
        },
        {
            title: 'Actividade',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            sorter: (a, b) => a.reference.localeCompare(b.reference),
            width: 100,
        },
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => `${dayJS(record.created_at).format('DD-MM-YYYY HH:mm')}`,
            width: 150,
        },
    ];

    return (
        <>
            <div className='w-full flex flex-col px-8 pt-6 pb-2'>
                <div className='menu-bar'>
                    <div className={`menu-bar-item ${selectedMenu === 'users' ? 'active' : ''}`} onClick={() => setSelectedMenu('users')}>
                        <p className='menu-bar-item-text'>Usuários</p>
                    </div>
                </div>
                <Input
                    placeholder="Pesquisar"
                    style={{ borderRadius: '0px', width: '15rem' }}
                    className='input-form mb-4'
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Table size='middle'
                    dataSource={data.filter(item => item.user.email.toLowerCase().includes(search.toLowerCase()))}
                    columns={columns}
                    loading={loading}
                    className='custom-table'
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                />
            </div>
        </>
    );
};

export default LogData;